<script>
import { Toast } from "mint-ui"
import { getLevelList } from "@/api/beijing28";
export default {
    name:'RedEnvelopeRule',
    components: {
        Header: () => import('components/base/Header'),
    },
    props:[
        'redPackData',
    ],
    data() {
        return {
            header: {
                mainTitle: getWord('event_rules'),
            },
            list:null,
        };
    },
    methods: {
        filter(item,classes){
            switch(item) {
                case -2:
                    return classes?'gray':getWord('expired')
                    break;
                case -1:
                    return classes?'red':getWord('inactivation')
                    break;
                case 0:
                    return classes?'green':getWord('withdrawable')
                    break;
                case 1:
                    return classes?'gray':getWord('withdrawn')
                    break;
                case 2:
                    return classes?'blue':getWord('inactivated')
                    break;
                default:
                    break;
            }
        },
        withdraw(){
            var ids=[];
            this.list.forEach(item=>{
                ids.push(item.id)
            })
            appRedPackWithdraws({
                ids
            }).then(result=>{

            })
        },
        toggleRule(){
            this.ruleShow=!this.ruleShow;
            return false;
        },
        formatLevel(id){
            var related=this.list.filter(item=>{
                return item.grade_id===Number(id)
            })
            if (related&&related.length>0) {
                return related[0].name
            }else {
                return getWord(['476','level'])
            }
        }
    },
    computed:{

    },
    created(){
        
    },
    mounted() {
        if (this.$refs.main) {
            this.CustomScroll(this.$refs.main.id);
        }

        getLevelList().then(result=>{
            if (result.data.code==='SUCCESS') {
                this.list=result.data.result;
            }else {
                Toast(result.data.msg)
            }
        })
    },
    beforeRouteUpdate(to,from,next){
        
    },
};
</script>

<template>
    <section id="red-envelope-rule" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
            :hide=true
        />
        <div class="inner" v-if="list">
            <h4>{{ getWord('event_date') }}:</h4>
            <p>{{ new Date(redPackData.startTime).toLocaleString() }} - {{ new Date(redPackData.endTime).toLocaleString() }}</p>
            <h4>{{ getWord('red_envelope_tips5') }}:</h4>
            <p>
                {{ getWord('coding_volume') }}: >= {{ redPackData.ruleConditions.week_code }}<br />
                {{ getWord('account_balance3') }}: >= {{ redPackData.ruleConditions.money }}<br />
                {{ getWord(['account','level']) }}: >= {{ formatLevel(redPackData.ruleConditions.grade_id) }}<br />
            </p>
            <h4>{{ getWord('event_rules') }}:</h4>
            <p>{{ getWord('red_envelope_tips7') }}</p>
        </div>        
    </section>
</template>

<style scoped lang='scss' type="text/css">
#red-envelope-rule {
    position: fixed;
    z-index: 11;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.template-2 {
        background: rgb(67,147,255);
        background: linear-gradient(180deg, rgba(67,147,255,1) 0%, rgba(67,147,255,1) 10%, rgba(180,212,255,1) 100%);
    }

    &.template-3 {
        background: rgb(236,40,41);
        background: linear-gradient(180deg, rgba(236,40,41,1) 0%, rgba(236,40,41,1) 15%, rgba(255,147,148,1) 100%);
    }

    .inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        margin: .5rem;
        border-radius: .25rem;
        padding: .5rem;

        h4 {
            font-size: .3rem;
            color: #FB3939;
        }

        p {
            font-size: .26rem;
            margin-bottom: .25rem;
        }
    }
    
}
</style>